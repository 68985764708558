import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import newsList from "../../../news"
import classes from "./NewsWidget.module.scss"

const NewsWidget: React.FC = () => {
  const { t, i18n } = useTranslation("home")
  let localedNewsList
  switch (i18n.language) {
    case "ja":
      localedNewsList = newsList.ja
      break
    default:
      localedNewsList = newsList.en
  }
  return (
    <>
      <div className={classes.NewsWidget}>
        <h2 className={classes.Heading}>{t("What’s New?")}</h2>
        <ul className={classes.NewsList}>
          {localedNewsList.map(el => (
            <li key={el.day} className={classes.NewsListItem}>
              <p className={classes.Date}>{el.day}</p>
              {el.contents.map(c => (
                <p className={classes.Content} key={c}>
                  {c}
                </p>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default NewsWidget
