import React, { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import classes from "./TwitterWidget.module.scss"

const TwitterWidget: React.FC = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    // scriptを読み込み
    const script = document.createElement("script")
    script.src = "https://platform.twitter.com/widgets.js"
    document.body.appendChild(script)
    // アンマウント時に一応scriptタグを消しておく
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  let twitterId
  switch (i18n.language) {
    case "ja":
      twitterId = "JMoF_PR"
      break
    default:
      twitterId = "JMoF_en"
  }

  return (
    <>
      <div className={classes.TwitterWidget}>
        <div key={twitterId}>
          <a
            className="twitter-timeline"
            data-lang={i18n.language}
            data-height="389"
            data-dnt="true"
            href={`https://twitter.com/${twitterId}?ref_src=twsrc%5Etfw`}
          >
            {`Tweets by ${twitterId}`}
          </a>
        </div>
      </div>
    </>
  )
}

export default TwitterWidget
