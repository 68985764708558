import React from "react"
import Img from "gatsby-image"

import ContactButton from "../../atoms/home/ContactButton"
import classes from "./PartnerEventCard.module.scss"

type Props = {
  name: string
  location: string
  // description: string
  annotation?: string
  url: string
  img?: {
    base64?: string
    width: number
    height: number
    src: string
    srcSet: string
  }
  color?: string
}

const PartnerEventCard: React.FC<Props> = props => {
  if (!props.img) {
    return <p>Image not found</p>
  }
  return (
    <div
      className={
        props.color === "light"
          ? classes.PartnerEventCardLight
          : classes.PartnerEventCardDark
      }
    >
      <Img fixed={props.img} />
      <h2 className={classes.Text}>{props.name}</h2>
      <h3 className={classes.Text}>{props.location}</h3>
      {/* <p>{props.description}</p> */}
      {props.annotation && (
        <p className={classes.Annotaion}>{props.annotation}</p>
      )}
      <ContactButton to={props.url} />
    </div>
  )
}

export default PartnerEventCard
