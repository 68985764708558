import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import PartnerEventCard from "../molecules/home/PartnerEventCard"
import partnerEventsList from "../../partnerEvents"
import classes from "./PartnerEvents.module.scss"

const PartnerEvents: React.FC = () => {
  const { t, i18n } = useTranslation("home")
  let localedPartnerEventsList
  switch (i18n.language) {
    case "ja":
      localedPartnerEventsList = partnerEventsList.ja
      break
    default:
      localedPartnerEventsList = partnerEventsList.en
  }

  let swiperWidth = 0
  if (typeof window !== "undefined") {
    swiperWidth = window.innerWidth
  }

  const data = useStaticQuery<GatsbyTypes.PartnerImageQuery>(graphql`
    query PartnerImage {
      images: allFile(
        filter: { relativePath: { regex: "$/partnerEventLogos/" } }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fixed(width: 300, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={classes.PartnerEvents}>
      <h2 className={classes.Heading}>{t("Our Friends")}</h2>
      <Swiper
        width={swiperWidth}
        spaceBetween={50}
        slidesPerView="auto"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          800: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
          1600: {
            slidesPerView: 4,
          },
        }}
        loop
        navigation
        pagination
        centeredSlides
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
      >
        {localedPartnerEventsList.map(ev => (
          <SwiperSlide key={ev.name}>
            <PartnerEventCard
              name={ev.name}
              location={ev.location}
              // description={ev.description}
              url={ev.url}
              img={
                data.images.edges.find(
                  el => el.node.relativePath === "partnerEventLogos/" + ev.img
                )?.node.childImageSharp?.fixed
              }
              color={ev.color}
              annotation={ev.annotation}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default PartnerEvents
