import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import announcement from "../../announcement"
import classes from "./AnnouncementBar.module.scss"

const AnnouncementBar: React.FC = () => {
  const { i18n } = useTranslation()
  const localedAnnouncement = announcement.ja

  if (i18n.language !== "ja") {
    return null
  }

  return (
    <>
      <div className={classes.AnnouncementBar}>
        <a
          href={localedAnnouncement.link}
          rel="noreferrer"
          className={classes.AnnouncementContent}
        >
          <b>{localedAnnouncement.content}</b>
        </a>
      </div>
    </>
  )
}

export default AnnouncementBar
