import React from "react"

import Button from "../common/Button"

type Props = {
  to: string
}

const ContactButton: React.FC<Props> = props => {
  return <Button name="contact" to={props.to} />
}

export default ContactButton
