import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

const TopImage: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.TopImageQuery>(graphql`
    query TopImage {
      desktopImage4: file(relativePath: { eq: "JMF25_tImg_ls_teaser.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobileImage4: file(relativePath: { eq: "JMF25_tImg_pr_teaser.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  if (
    !data?.desktopImage4?.childImageSharp?.fluid ||
    !data?.mobileImage4?.childImageSharp?.fluid
  ) {
    return <div>Picture not found</div>
  }

  const img4 = [
    data.desktopImage4.childImageSharp.fluid,
    {
      ...data.mobileImage4.childImageSharp.fluid,
      media: `(max-width: 600px)`,
    },
  ]

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      effect="fade"
      speed={1500}
      autoplay={{
        delay: 7500,
      }}
      pagination
    >
      <SwiperSlide>
        <Img fluid={img4} style={{ backgroundColor: "#000" }} />
      </SwiperSlide>
    </Swiper>
  )
}

export default TopImage
