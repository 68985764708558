import React from "react"

import TopImageAtom from "../atoms/home/TopImage"
import classes from "./TopImage.module.scss"

const TopImage: React.FC = () => {
  return (
    <>
      <div className={classes.TopImage}>
        <TopImageAtom />
      </div>
    </>
  )
}

export default TopImage
