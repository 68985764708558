import React from "react"
import { PageProps } from "gatsby"

import SEO from "../utils/seo"
import AnnouncementBar from "../components/organisms/AnnouncementBar"
import TopImage from "../components/organisms/TopImage"
import PartnerEvents from "../components/organisms/PartnerEvents"
import InformationWidgets from "../components/organisms/InformationWidgets"
import NextOrganization from "../components/atoms/home/NextOrganization"

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title="Japan Meeting of Furries" />
      <AnnouncementBar />
      <TopImage />
      <NextOrganization />
      <InformationWidgets />
      <PartnerEvents />
    </>
  )
}

export default IndexPage
