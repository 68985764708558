import React from "react"

import NewsWidget from "../atoms/home/NewsWidget"
import TwitterWidget from "../atoms/home/TwitterWidget"
import classes from "./InformationWidgets.module.scss"

const InformationWidgets: React.FC = () => {
  return (
    <>
      <div className={classes.InformationWidgets}>
        <NewsWidget />
        <TwitterWidget />
      </div>
    </>
  )
}

export default InformationWidgets
