import React from "react"
import { useTranslation } from "react-i18next"
import classes from "./NextOrganization.module.scss"

const NextOrganization: React.FC = () => {
  const { t } = useTranslation("home")
  return (
    <div className={classes.NextOrganizationWrapper}>
      <h2 className={classes.Heading}>{t("Next Convention")}</h2>
      <p className={classes.Date}>
        {t("January 10th (Fri.) - 12th (Sun.), 2025")}
      </p>
      <p className={classes.Location}>
        {t("Loisir Hotel Toyohashi (Toyohashi, Aichi, Japan)")}
      </p>
    </div>
  )
}

export default NextOrganization
